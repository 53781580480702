.onboard-tabs {
    .Desk-view {
        @media (max-width: 600px) {
            display: none;
        }
    }

    .Mobi-view {
        display: flex;
        padding: 0.5rem 15px;
        border-bottom: 4px solid var(--blue);

        @media (min-width: 600px) {
            display: none;
        }

        .Form-step {
            width: 45px;
            height: 45px;
        }
    }

    .nav-tabs {
        border-bottom: none;
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        border: none;
        border-bottom: 6px solid var(--blue);
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: var(--blue);
        border-radius: 0 !important;
    }

    .nav-tabs .nav-item {
        margin-right: 10px;
    }

    .nav-tabs .nav-link {
        border: none;
        border-bottom: 6px solid var(--gray3);
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: var(--gray3);
        width: 200px;
        text-align: left;
        padding: 10px 0px 14px 0;
        border-radius: 0 !important;
    }

    .tab-content {
        padding: 15px 10px;

        @media (max-width: 600px) {
            padding: 15px;
        }
    }
}

.custom-select {
    >div {
        // height: 48px;
        // padding: 5px 16px 8px 10px !important;
        border: 1px solid #e8e8e8;
        border-radius: 0.5rem;
        // div:first-child {
        //     padding: 11px;
        // }
    }
}

.check-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--gray4);
    margin-left: 30px;
    width: 79%;
}

.payroll-sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 123%;
    color: var(--gray4);
    padding: 10px 0;

    a {
        color: var(--blue);
        font-weight: 600;
    }
}

.pay-tooltip {
    opacity: 2.9 !important;
    width: 400px !important;

    @media (max-width: 600px) {
        width: 200px !important;
    }


    .rc-tooltip-content {
        width: 100%;
    }

    .rc-tooltip-arrow {
        border-left-color: var(--gray) !important;
    }
}



.rc-tooltip-content {
    width: 60%;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
    display: none;
}

.rc-tooltip-inner {
    background: var(--gray) !important;
    border-radius: 8px !important;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
    border-right-color: var(--gray) !important;
}

.bank-tooltip {
    .rc-tooltip-content {
        width: 100%;
    }

    .rc-tooltip-inner {
        background: var(--white) !important;
        border-radius: 8px !important;
        color: var(--black);
    }

    .rc-tooltip-placement-right .rc-tooltip-arrow {
        border-right-color: var(--white) !important;
    }
}

.bank-check {
    .form-check-label {
        margin-top: 0.25rem;
    }

    @media (max-width: 600px) {
        .form-check {
            justify-content: center;
            display: flex;
            align-items: flex-start;
        }
    }
}

.help-circle {
    fill: var(--blue);
    color: var(--white);
    font-size: 23px;
    vertical-align: middle;
    margin-bottom: 3px;
}

.create-board {
    .payroll-client {

        .was-validated .form-check-input:valid:checked,
        .form-check-input.is-valid:checked {
            border: 2px solid #0d6efd;
            background-image: url('../../../assets/images/check_tick.svg');
            background-color: #0d6efd !important;
        }
    }
}

.check-custom {
    .ack-step-last {
        .form-check-input {
            @media (max-width: 600px) {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.ack-step-last {
    .form-check-input {
        margin-top: 8px;
        width: 20px;
        height: 20px;
        margin-right: 10px;

    }

    .form-check-label {
        color: #000 !important;
        margin-top: 0.5rem !important;
    }

    a {
        color: var(--blue);
        font-weight: 500;
    }

    span {
        color: #000
    }
}

.boarding-layout .was-validated .ack-step-last .form-check-input:valid:checked,
.boarding-layout .form-check-input.is-valid:checked {
    background-color: #0d6efd !important;
}

.asterisk {
    color: var(--red);
}

.was-validated .is-signatory .form-check-label {
    color: #000 !important;
}