.board-bg {
    background: rgba(0, 57, 109, 0.09);
    border-radius: 2px;
}

.board-container {
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;

    @media (max-width: 600px) {
        padding: 0;
    }
}

.boarding-layout {
    margin-top: 82px;
    padding: 1.5rem;

    @media (max-width: 768px) {
        padding: 0;
    }
}

.create-board {
    background: var(--white);
    padding: 1.5rem;
}

.board-main-section {
    .create-board {
        background: var(--white);
        

        @media (max-width: 600px) {
            padding: 0;
            min-height: 38rem;
        }
    }
}


.client-d-tab {

    .nav-link.active,
    .show>.nav-link {
        background: var(--primary);
        border-radius: 3px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
    }

    .nav-item {
        margin-top: 10px;
    }

    .nav-link {
        background: var(--white);
        border-radius: 3px;
        padding: 17px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--grey1);

        .activeIcon {
            display: none;
        }

        &.active {
            svg {
                path {
                    fill: var(--white);
                }
            }

            .activeIcon {
                display: inline-block;
            }

            .inactiveIcon {
                display: none;
            }
        }
    }
}

.board-right {
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--black2);
        ;
    }

    h5 {
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        color: var(--black1);
    }

    .sub-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 157%;
        color: #787878;
    }
}

.create-btn {
    @media (max-width: 600px) {
        width: 100%;
    }
}

.board-tab-sections {
    h5 {

        @media (max-width: 600px) {
            display: none;
        }
    }
}

.phone-group {
    .input-group-text {
        background-color: var(--white) !important;
        border: 1px solid var(--gray2);
    }

    input {
        border-radius: 0 0.375rem 0.375rem 0 !important;
    }
}

.calendar-icon {
    background-image: url('../../assets/images/calendar_month.png');
    background-position: 50%;
    background-size: cover;
    cursor: pointer;
    height: 17px;
    position: absolute;
    right: 20px;
    top: 43px;
    width: 17px;
}

.form-select{
    -webkit-appearance: menulist!important;
    -moz-appearance: menulist!important;
    -ms-appearance: menulist!important;
    -o-appearance: menulist!important;
    appearance: menulist!important;
}

.pass-invalid{
    li{
        padding-left: 10px;
    }
}