.emp-onboard-tabs {
    .nav-tabs .nav-link {
        width: 150px;
    }
}

.emp-sub-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--black1);
}

.employee-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 123%;
    color: var(--gray4);
    max-width: 70%;
    margin-bottom: 15px;

    a {
        color: var(--blue);
        font-weight: 600;
    }
}

.employee-points {
    font-weight: 900;
    font-size: 14px;
    line-height: 22px;
    color: var(--black1);

    li {
        font-weight: 500;
    }

    .active li {
        color: var(--blue);
        font-weight: 600;
    }
}

.employee-fedral {
    font-weight: 400;
    font-size: 14px;
    line-height: 123%;
    color: var(--gray4);
    width: 70%;
}

.blue_bg_card {
    background: #EBF2F8;
}

.white_bg_card {
    background: #FFFFFF;
}

.onboard_task_title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1A202C;
}

.file_list {
    max-width: 581px;
    background: var(--white);
    margin: 5px 0px;
    padding: 10px 10px;

    // .file-card {
    //     margin-top: 15px;
    // }

    .file_name {
        color: #77767E;
        font-weight: 500;
        font-size: 13px;
        line-height: 12px;
    }

    .file_size {
        font-weight: 300;
        font-size: 12px;
        line-height: 12px;
        color: #77767E;
        margin-right: 10px;
    }

    .remove_file {
        line-height: normal;
        cursor: pointer;
        font-size: 12px;
        color: #77767E;

        svg {
            font-size: 20px;
        }
    }
}

.task_card {
    border: 1px solid #2773B1;
    border-radius: 8px;

    .task_detail {
        flex-basis: 70%;

        p {
            margin: 0px;
        }

        .task_title {
            font-weight: 400;
            font-size: 18px;
            color: #000000;
            margin-bottom: 8px;
        }

        .task_time {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #7A7A7A;
        }
    }

    .task_action {
        flex-basis: 30%;
    }
}

.emp-onboard-tabs {
    .nav-tabs .nav-link {
        width: 150px;
    }
}

.emp-sub-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--black1);
}

.employee-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 123%;
    color: var(--gray4);
    max-width: 70%;
    margin-bottom: 15px;

    a {
        color: var(--blue);
        font-weight: 600;
    }
}

.employee-points {
    font-weight: 900;
    font-size: 14px;
    line-height: 22px;
    color: var(--black1);

    li {
        font-weight: 500;
    }

    .active li {
        color: var(--blue) !important;
        font-weight: 600 !important;
        padding-top: 7px;
    }
}

.employee-fedral {
    font-weight: 400;
    font-size: 14px;
    line-height: 123%;
    color: var(--gray4);
    width: 70%;
}

.drag-background {
    background: #F9F9F9;
    border-radius: 8px;
}

.employee-document-upload {
    min-width: 170px !important;
    height: 125px !important;
    max-width: 100% !important;
    margin: auto;
    background: var(--white);
    text-align: center;

    .bXgVCj {
        font-weight: 600;
        font-size: 16px !important;
        line-height: 120%;
        letter-spacing: -0.32px;
        color: #AFAFAF !important;

        @media (max-width: 300px) {
            font-size: 10px !important;
        }
    }

    .file-types {
        @media (max-width: 300px) {
            font-size: 10px !important;
        }
    }

    // .sc-hLBbgP.bXgVCj{
    //     display: none;
    // }
    svg {
        display: none;
    }

    .dmajdp {
        display: inline !important;
    }
}

.view_task_modal {
    .modal-header {
        box-shadow: none;
        padding: 20px 10px 0 20px;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: var(--black1);

    }

    .modal-body {
        padding: 0px 10px 20px 20px;

    }

    .btn-close {
        display: none;
    }
}

.onboard-task-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: var(--black1);
}

.onboard-sub-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #7A7A7A;
}

.onboard-body {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--black1);

    a {
        color: var(--blue);
        font-weight: 600;
    }
}

#react-select-2-listbox,
#react-select-3-listbox,
#react-select-7-listbox,
#react-select-8-listbox,
#react-select-9-listbox,
#react-select-10-listbox {
    z-index: 9 !important;
}

.successModal {
    text-align: center;

    .modal-header {
        display: none;
    }

    h5 {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: var(--black);
    }

    .sub-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: var(--black);

    }
}

.success-btn {
    background: #6AA84F !important;
    border-radius: 5px !important;
    width: 145px;

}

.cursor-revert {
    cursor: revert !important;
}

.doc-check {
    .form-check-inline {
        @media (max-width: 768px) {
            display: flex;
        }
    }

    .form-check-input {
        @media (max-width: 1300px) {
            margin-right: 3px !important;
        }
    }

    .form-check-label {
        @media (max-width: 1300px) {
            margin-left: 10px !important;
        }
    }

    .online-doc-upload {
        @media (max-width: 1300px) {
            margin-top: 10px !important;
        }
    }

}

.eligible-check {
    .form-check-input {
        @media (max-width: 768px) {
            margin-right: 3px !important;
        }
    }
}

.attachment-ul {
    list-style-type: none;
    padding-left: 1rem;
}

.preview-tool {
    position: relative;
    overflow: hidden;

    iframe {
        width: 100% !important;
        position: relative;
        top: -50px;

        @media (max-width: 768px) {
            position: relative;
            top: -23px;
        }
    }
}


// @-moz-document url-prefix() {
//     .pdf-preview iframe {
//         display: none;
//     }
// }

.boarding-layout {

    .was-validated .form-check-input:valid:checked,
    .form-check-input.is-valid:checked {
        border: 2px solid #0d6efd;
        background-image: url('../../../assets/images/check_tick.svg');
        background-color: #FFF !important;
    }


    .was-validated .form-check-input:valid,
    .form-check-input.is-valid {
        border-color: #198754;
    }

    .was-validated .form-check-input:valid~.form-check-label {
        color: #000 !important;
    }
}

.type-contractor {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #646569;
}

.custom-select>div {
    padding-bottom: 5px;
    padding-top: 5px;
}

.file-span {
    font-size: 14px;
    color: #555555;
    font-weight: 400;
    max-width: 70%;
}

.personal-date-picker {
    .datepicker-payscheme {
        height: 49px !important;
    }
}
.contact-check{
     .form-check-label {
        margin-top: 6px !important;
    }
   
}
.was-validated .contact-check .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
    border: 2px solid #0d6efd;
    background-image: url('../../../assets/images/check_tick.svg');
    background-color: #0d6efd !important;
}


.was-validated .contact-check .form-check-input:valid,
.form-check-input.is-valid {
    border-color: #198754;
}

.was-validated .contact-check .form-check-input:valid~.form-check-label {
    color: #000 !important;
}
.iframe-wrapper{
    .row{
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
    }
    .button-wrapper{
        width: auto;
    }
}
@media (max-width: 768px) {
    .iframe-wrapper{
        .row{
            flex-direction: column;
        }
        .button-wrapper{
            margin-top: 20px;
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
    }
}