.total-detail-pending-hours {
  display: flex;
  flex-direction: column;
  p {
    font-family: "Lato";
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    color: #f04b4b;
    margin: 0;
  }
  h5 {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #f04b4b;
    margin: 0;
  }
}
.border-cus-class-detail {
  border: 1px solid #e4e4e7;
}
.detail-hours-wrapper {
  display: flex;
  justify-content: space-between;
  width: 30%;
}
.detail-all-btn-wrapper {
  margin: 0;
}
.detail-select-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modal-detail-wrapper {
  .modal-dialog {
    width: 95%;
  }
}
.modal-detail-wrapper {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.detail-back-btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  .setting-backbutton {
    h5 {
      padding: 0 0.5rem 0.5rem;
    }
  }
  .list-text-wrapper {
    border: none;
  }
}
.modal-detail-main-wrapper {
  justify-content: center;
  gap: 1rem;
  .approve-btn-active {
    margin: 0;
  }
}
.modal-add-detail-main-wrapper {
  justify-content: end;
  gap: 1rem;
  padding: 1rem 2rem;
  .approve-btn-active {
    margin: 0;
    padding: 0.75rem 1.5rem;
    width: 140px;
  }
}

/* Overall Modal Styles */

.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 4px 20px 1px #0000001a;
}

/* Pending Modal Styles */

.modal-title h4 {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #09090b;
  text-transform: uppercase;
  margin: 0;
}

.modal-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 32px;
  border-bottom: 1px solid #e4e4e7;
}

.modal-close {
  cursor: pointer;
}

.modal-body-wrapper {
  // margin: 0 auto;
  padding: 1rem 1rem 1.5rem;
  border-bottom: 1px solid #e4e4e7;
}
.custom-height-wrapper {
  height: 450px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
  }
}
.status-flex-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.status-flex-container .day {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  color: #71717a;
  margin: 0;
}

.status-flex-container .delete-edit-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.status-flex-container .delete-edit-wrapper > img {
  cursor: pointer;
}

.status-condition {
  margin-top: 5px;
}
.status-detail-wrapper {
  display: flex;
  align-items: center;
  p {
    font-family: "Lato";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #09090b;
    padding: 0 0 0 0.5rem;
    margin: 0;
  }
}
.status-condition .status {
  position: relative;
}

.status-condition .status::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: #e5b405;
  border-radius: 50%;
}

.form-wrapper {
  margin-top: 20px;
}

.form-wrapper form > div:not(:last-child) {
  margin-bottom: 16px;
}

.clock-in-out-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  .css-i4bv87-MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
}

.clock-in-out {
  flex: 1 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  width: 40%;
  label {
    margin: 0 0 8px 0;
  }
}

.form-wrapper label {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #515253;
}

.form-wrapper {
  input {
    display: block;
    // width: -webkit-fill-available;
    // border: 1px solid #e4e4e7;
    border-radius: 6px;
    padding: 0px 8px;
    // margin-top: 4px;
    outline: none;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #71717a;
    height: 36px;
  }
}
.height-class-input {
  height: 38px;
}

.form-wrapper textarea {
  display: block;
  width: -webkit-fill-available;
  // background: #f4f4f5;
  border: 1px solid #e4e4e7;
  border-radius: 6px;
  padding: 8px 8px 0;
  margin-top: 4px;
  outline: none;
  height: 42px;
  resize: none;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #71717a;
}

.form-wrapper ::placeholder {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #71717a;
}

.footer-bttn-flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 18px;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
}

.modal-btn-wrapper {
  border-radius: 3px;
  padding: 11px 42px;
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  outline: 0;
  border: 0;
  cursor: pointer;
}

.modal-btn-wrapper.approve-bttn {
  background: #82a476;
}

.modal-btn-wrapper.reject-bttn {
  background: #f04b4b;
}
.cus-width-modaltext {
  width: 85%;
}
.date-main-wrapper {
  box-shadow: 0px 0px 4px 0px #0000001a;
  border-radius: 10px;
  margin: 0.5rem 0;
  width: 85%;
}
.date-modal-wrapper {
  display: flex;
  width: 95%;
  justify-content: space-between;
}
.sub-modal-date-wrapper {
  padding: 0 10px;
  margin: 10px 0;
  border-left: 1px solid #e4e4e7;
  h5 {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
  }
  p {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
/* Approval Popup Modal */

.modal-wrapper.approval-final-modal {
  border-radius: 10px;
}

.modal-body-wrapper {
  p {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin: 0;
    color: #09090b;
  }
}
.modal-confirm-body-wrapper {
  p {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin: 0;
    color: #71717a;
  }
}
.approval-confirm-bttn {
  text-transform: capitalize;
  background: #2773b1;
  color: #fff !important;
  &:hover {
    color: #fff;
  }
}

.approval-final-modal .modal-header,
.approval-final-modal .modal-body-wrapper {
  border: 0;
}

.approval-final-modal {
  .modal-header {
    padding: 1rem;
  }
}

.approval-final-modal .modal-body-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}
.modal-text-error-wrapper {
  background: #f2f7f9;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 10px 8px;
  margin: 1rem 0 0;
  h5 {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #f04b4b;
    display: flex;
    align-items: center;
  }
  img {
    padding: 0 10px 0 0;
  }
  p {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #000;
  }
}
.select-tag-wrapper {
  .css-3iigni-container {
    width: 210px;
  }
}
.disabled-cus-wrapper {
  background: #f2f2f2;
  img {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.error-message {
  color: #f04b4b !important;
}
.detail-table-container {
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    padding: 1.1rem 0.5rem;
  }
}
.today-hour-wrapper {
  cursor: pointer;
}
.border-hours-holiday {
  border: 1px solid #e4e4e7;
  border-radius: 6px;
  background: #e8e8e8;
}
.job-main-location-wrapper {
  display: flex;
  justify-content: space-between;
  grid-gap: 1rem;
}
.job-location-wrapper {
  width: 100%;
}
.job-code-wrapper {
  width: 100%;
}
.sub-clock-in-out-wrapper {
  width: 90%;
}
.span-week-wrapper {
  padding: 1.1rem 1rem !important;
  h5 {
    color: #18181b;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
  }
  span {
    color: #2773b1;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}
.summary-row-wrapper {
  background: #f5f5f5;
  padding: 0 1rem !important;
}
.category-details-wrapper {
  background: #f5f5f5;
  display: flex;
  justify-content: end;
  grid-gap: 1rem;
}
.category-sub-details {
  h5 {
    color: #18181b;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
  }
  span {
    color: #2773b1;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}
@media (max-width: 1250px) {
  .detail-select-wrapper {
    flex-wrap: wrap;
    grid-gap: 1rem;
  }
}
@media (max-width: 1024px) {
  .total-detail-pending-hours {
    padding: 0 1rem;
    align-items: end;
  }
  .detail-all-btn-wrapper {
    padding: 1rem 0;
    border: none;
    flex-direction: column;
    grid-gap: 1rem;
    width: 100%;
  }
  .detail-select-wrapper {
    flex-direction: column;
  }
  .detail-hours-wrapper {
    width: 100%;
  }
  .modal-detail-wrapper {
    .modal-dialog {
      width: 100%;
    }
  }
  .select-tag-wrapper {
    .css-3iigni-container {
      width: auto;
    }
  }
  .sub-clock-in-out-wrapper {
    width: 100%;
  }
  .responsive-width-cus-wrapper {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .modal-wrapper {
    width: calc(100% - 30px);
    max-width: 100%;
  }
  .modal-header {
    padding: 20px 18px 20px 18px;
  }
  .modal-body-wrapper {
    padding: 18px 16px 32px;
  }
  .footer-bttn-flex-wrapper {
    padding: 16px 18px;
  }
  .modal-btn-wrapper {
    padding: 11px 36px;
  }
  .cus-width-modaltext {
    width: 100%;
  }
  .date-main-wrapper {
    width: 100%;
  }
  .date-modal-wrapper {
    width: 100%;
  }
  .total-detail-pending-hours {
    width: 30%;
    padding: 0;
    align-items: start;
  }
  .clock-in-out-wrapper {
    flex-direction: column;
    .css-z3c6am-MuiFormControl-root-MuiTextField-root {
      width: 100%;
    }
  }
  .clock-in-out {
    width: 100%;
  }
  .job-main-location-wrapper {
    flex-direction: column;
  }
}
@media (max-width: 600px) {
  .detail-all-btn-wrapper {
    margin: 0;
    // justify-content: end;
  }
  .modal-detail-main-wrapper {
    // flex-direction: column;
    .approve-btn-active {
      width: 100%;
    }
    .deny-btn-active {
      width: 100%;
    }
  }
  .modal-add-detail-main-wrapper {
    // flex-direction: column;
    .approve-btn-active {
      width: 100%;
    }
  }
  .details-modal-wrapper {
    .modal.show .modal-dialog {
      width: 100%;
      min-width: 300px;
    }
    .fade.modal.show {
      display: flex !important;
    }
  }
}
@media (max-width: 515px) {
  .fade.modal.show {
    display: flex !important;
  }
}
@-moz-document url-prefix() {
  .clock-in-out-wrapper {
    flex-wrap: nowrap;
  }
  .sub-clock-in-out-wrapper {
    width: 90%;
  }
  @media (max-width: 600px) {
    .sub-clock-in-out-wrapper {
      width: 100%;
    }
  }
}
